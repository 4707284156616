import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ pageContext: { locale } }) => {
  console.log("locale",locale);
  return (
    <Layout locale={locale}>
      <SEO title="404: Not found" />
      <div className="text-center my-4">
        <h1>NOT FOUND</h1>
        <p>La pagina che stai cercando non è disponibile</p>
      </div>
    </Layout>
  )
}
export default NotFoundPage
